import { BothPlatform, MyData, Protect } from "src/assets/svgs";
import { cls } from "src/theme/colors";
import styled from "styled-components";

function DataTechExplained() {
  return (
    <S.Container>
      <S.TextBox>
        <S.MainText>이제는 [ 데이터테크 ]</S.MainText>
        <S.SubText>더 간편하게 연동하고 데이터 조회까지!</S.SubText>
      </S.TextBox>
      <S.IconBox>
        <S.IconContent>
          <Protect />
          <S.IconText>
            개인정보 유출
            <br />
            걱정 없이
          </S.IconText>
        </S.IconContent>
        <S.IconContent>
          <MyData />
          <S.IconText>
            마이데이터는
            <br />내 휴대폰에만 저장
          </S.IconText>
        </S.IconContent>
        <S.IconContent>
          <BothPlatform />
          <S.IconText>
            안드로이드, 애플
            <br />
            모두 사용 가능
          </S.IconText>
        </S.IconContent>
      </S.IconBox>
    </S.Container>
  );
}

export default DataTechExplained;

const S = {
  Container: styled.div`
    z-index: 10;
    width: 100%;
    height: 403px;
    background-color: ${cls.mainBlue7};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  `,
  TextBox: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
  `,
  MainText: styled.p`
    color: ${cls.white};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.552px;
  `,
  SubText: styled.p`
    color: ${cls.white};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.368px;
  `,
  IconBox: styled.div`
    display: flex;
    align-items: center;
  `,
  IconContent: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  `,
  IconText: styled.p`
    color: ${cls.white};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.276px;
    margin-top: 10px;
  `,
};
