import { Connect } from "src/assets/svgs";
import { styled } from "styled-components";

function ToConnect() {
  return (
    <S.Container>
      <S.Box>
        <S.Content>
          <S.Top>
            <Connect className="connect" /> (기업전용)
          </S.Top>
          <S.MainText>
            마이디 사용자와
            <br />
            데이터 거래가 하고 싶다면?
          </S.MainText>
          <S.SubText>
            마이디 커넥트 서비스는
            <br />
            마이디 사용자의 데이터를
            <br />
            구매할 수 있는 서비스입니다.
          </S.SubText>
        </S.Content>

        <S.HomePageBtn href="https://service.biz.myd.world/" target="_blank" rel="noopener noreferrer">
          홈페이지 바로가기 &gt;
        </S.HomePageBtn>
      </S.Box>
    </S.Container>
  );
}

export default ToConnect;

const S = {
  Container: styled.section`
    width: 100%;
    height: 536px;
    background-color: #e7e9ee;
  `,
  Box: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  `,
  Content: styled.div`
    margin-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  Top: styled.div`
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.414px;
    align-items: center;
    white-space: nowrap;

    .connect {
      margin-right: 4px;
    }
  `,
  MainText: styled.p`
    margin-top: 42px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.644px;
    text-align: center;
  `,
  SubText: styled.p`
    margin-top: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.414px;
    text-align: center;
  `,

  HomePageBtn: styled.a`
    margin-top: 60px;
    cursor: pointer;
    width: 240px;
    height: 80px;
    color: white;
    background-color: #1890ff;

    border-radius: 13.3px;
    padding: 25.333px 53px 24.667px 53px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: 29.333px;
    display: flex;
  `,
};
