import { MydLogo } from "src/assets/svgs";
import { cls } from "src/theme/colors";
import styled from "styled-components";

export default function Header() {
  return (
    <S.Container>
      <S.Content>
        <MydLogo />
      </S.Content>
    </S.Container>
  );
}

const S = {
  Container: styled.header`
    width: 100%;
    color: ${cls.mainBlack};
    font-size: 18px;
    background: ${cls.white};
  `,
  Content: styled.div`
    width: calc(100% - 48px);
    margin: 60px 24px 80px 24px;
    display: flex;
    align-items: center;
  `,
};
